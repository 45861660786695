<template>
  <div class="analysis">
    <p-container>
      <a-tabs default-active-key="1" @change="callback">
        <a-tab-pane key="1" tab="柱状图">
          <template slot="toolbar">
            <!-- <a-button type="primary" @click="handleEdit">编辑</a-button>
            <a-button type="primary">Excel导出</a-button>-->
            <div>
              <a-spin tip="Loading..." :spinning="isLoading">
                <section class="upload-police-serach-container">
                  <div style="display: flex; align-items: center">
                    <div class="input-line">
                      <!-- multiple:是否支持多选；action：上传地址；method：请求方法；headers：设置上传的请求头部 -->
                      <a-upload
                        style="display: flex; margin: 5px"
                        name="file"
                        :multiple="false"
                        :action="uploadUrl"
                        method="post"
                        @change="uploadFile"
                      >
                        <a-button type="primary">
                          <a-icon type="upload" />导入文件
                        </a-button>
                      </a-upload>
                    </div>
                  </div>
                </section>
              </a-spin>
            </div>

            <!-- <a-button type="primary">批量分类梳理</a-button>
        <a-button type="primary">申请号批量查询</a-button>
            <a-button type="primary">启动评审</a-button>-->
            <a-input-search placeholder="搜索" enter-button="搜索" size="default" @search="onSearch" />
          </template>

          <a-table
            bordered
            :data-source="dataSource"
            :columns="columns"
            size="middle"
            :row-selection="rowSelection"
            :loading="loading"
            :pagination="pagination"
          >
            <a
              href="javascript:;"
              @click="cxdShowModal(col)"
              slot="cxdAction"
              slot-scope="text, col"
            >多尺度分析</a>

            <span slot="wcrqkAction" slot-scope="text, col">
              <p
                @click="wcrqkShowModal(wcrmc, col.cgguid)"
                v-for="wcrmc in col.wcrname"
                :key="wcrmc"
                :class="classname"
              >{{ wcrmc }}</p>
            </span>
            <span slot="wcdwqkAction" slot-scope="text, col">
              <p
                href="javascript:;"
                @click="wcdwqkShowModal(dwmc, col.cgguid)"
                v-for="dwmc in col.dwmc"
                :key="dwmc"
                :class="classname"
              >{{ dwmc }}</p>
            </span>
            <span slot="zcxmqkAction" slot-scope="text, col">
              <p
                href="javascript:;"
                @click="zcxmqkShowModal(zcxmmc, col.cgguid)"
                v-for="zcxmmc in col.zcxmmc"
                :key="zcxmmc"
                :class="classname"
              >{{ zcxmmc }}</p>
            </span>
            <span slot="hjqkAction" slot-scope="text, col">
              <p
                href="javascript:;"
                @click="hjqkShowModal(hjxmmc, col.cgguid)"
                v-for="hjxmmc in col.hjxmmc"
                :key="hjxmmc"
                :class="classname"
              >{{ hjxmmc }}</p>
            </span>
            <span slot="zzqkAction" slot-scope="text, col">
              <p
                href="javascript:;"
                @click="zzqkShowModal(lb, col.cgguid)"
                v-for="lb in col.lb"
                :key="lb"
                :class="classname"
              >{{ lb }}</p>
            </span>
            <a
              href="javascript:;"
              @click="jjxyqkShowModal(col)"
              slot="jjxyqkAction"
              slot-scope="text, col"
            >经济效益</a>
          </a-table>
          <page-header-wrapper>
            <a-card :bordered="false">
              <div class="Echarts">
                <div id="main1" style="width: 900px; height: 400px"></div>
              </div>
            </a-card>
          </page-header-wrapper>
        </a-tab-pane>
        <a-tab-pane key="2" tab="优势领域" force-render>
          <!-- TODO: -->
          <a-table
            :columns="tab2Columns"
            :data-source="tab2DataSource"
            bordered
            size="middle"
          >
            <template #lookOver>
              <a @click="lookOver">查看</a>
            </template>
          </a-table>
        </a-tab-pane>
      </a-tabs>
    </p-container>
  </div>
</template>

<script>
import { getdcdfx, getzzt } from "../../../../api/result";
const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    console.log(selected, selectedRows, changeRows);
  },
};
const columns = [
  {
    title: "项目名称",
    dataIndex: "cgmc",
    //width: "10%",
  },
  {
    title: "项目类别",
    dataIndex: "cmflmc",
  },
  {
    title: "多尺度分析",
    dataIndex: "cmfldm",
  },
];

const tab2Columns = [
  {
    title: "年度",
    dataIndex: "a",
    width: 180,
  },
  {
    title: "项目名称",
    dataIndex: "b",
  },
  {
    title: "优势领域",
    dataIndex: "c",
    key: "lookOver",
    scopedSlots: { customRender: "lookOver" },
    width: 180,
  },
];
const dataSource = [];
const tab2DataSource = [
  {
    a: 1,
    b: 2,
    c: 3,
  },
];
export default {
  name: "Echarts",
  methods: {
    lookOver() {
      console.log("查看");
    },
  },
  data() {
    return {
      currentPage: 1,
      pagination: {
        total: 100,
        pageSize: 20,
        onChange: this.changePage,
      },
      loading: false,
      treeData: [],
      valuechoose: "",
      rowSelection,
      dataSource,
      columns,
      cxdVisible: false,
      wcrqkVisible: false,
      wcdwqkVisible: false,
      zcxmqkVisible: false,
      hjqkVisible: false,

      bzqkVisible: false,
      zzqkVisible: false,
      jjxyqkVisible: false,
      classname: "classname",
      cxdText: "",
      wcrText: "",
      wcdwText: "",
      zcxmqkText: "",
      hjqkText: "",
      zzqkText: "",
      jjxyText: "",
      uploading: false,
      isLoading: false,
      //是否禁用按钮
      btnState: true,
      //传给后台的列表数据
      list: [],
      //上传的数据
      uploadData: [],
      //文件数据
      fileList: [],
      //上传文件地址
      uploadUrl: `http://localhost:8081/cgk/upload?filetype=基本情况`,
      //上传文件请求头
      headers: {
        authorization: "authorization-text",
      },
      data: {
        filetype: "基本情况",
      },
      text: "",

      data1: "",
      data2: "",
      data3: "",
      tab2Columns,
      tab2DataSource,
    };
  },
  methods: {
    changePage(page) {
      this.getTableApi(page);
    },
    // TODO:获取分页数据
    async getTableApi(page) {
      this.currentPage = page;
      console.log(this.currentPage, this.pagination.pageSize);

      const res = await getdcdfx({
        current: this.currentPage,
        size: this.pagination.pageSize,
      });

      const { data } = res.data;
      this.dataSource = data || this.dataSource;
      console.log(this.dataSource);
      this.$set(this.pagination, "total", res.data.size);
    },
    drawChart() {
      // 基于准备好的dom，初始化echarts实例
      var echarts = require("echarts");
      const myChart = echarts.init(document.getElementById("main1"));
      var that = this;
      // 指定图表的配置项和数据
      var option = {
        title: {
          text: that.text,
          x: "center",
          y: "top",
          align: "center",
          textStyle: {
            color: "#666666",
            fontWeight: "bolder",
            fontSize: 28,
          },
        },
        tooltip: {
          trigger: "axis",
          formatter: function (params) {
            // 自定义toolTip提示框显示内容
            // alert(JSON.stringify(params));
            var res = "";
            for (var i = 0; i < params.length; i++) {
              if (i === 0) {
                res +=
                  "<p>" + params[i].seriesName + ":" + params[i].data + "</p>";
              }
              if (i === 1) {
                res +=
                  "<p>" + params[i].seriesName + ":" + params[i].data + "</p>";
              }
            }
            return res;
          },
          axisPointer: {
            // type: 'cross',  鼠标移动上去显示 虚刻度线
            crossStyle: {
              color: "#999",
            },
          },
        },
        toolbox: {
          show: false,
          top: 10,
          right: 10,
          feature: {
            mark: { show: false },
            magicType: { show: false, type: ["line", "bar"] },
            restore: { show: false },
            saveAsImage: { show: false },
          },
        },
        grid: {
          // 调整统计图上下左右边距
          top: 80,
          right: 0,
          bottom: 50,
          left: 0,
        },
        legend: {
          top: 45,
          // 统计图两个方块到Title的距离
          left: "center",
          data: ["标准值", "测评值"],
        },
        calculable: true,
        xAxis: [
          {
            type: "category",
            data: this.data1,
            axisPointer: {
              type: "shadow",
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#666666",
                fontSize: 14,
              },
            },
            axisLine: {
              // X轴线的颜色
              lineStyle: {
                color: "#D5D5D5",
                width: 2,
              },
            },
            axisLabel: {
              interval: 0,
              textStyle: {
                color: "black", //更改坐标轴文字颜色
                fontSize: 14, //更改坐标轴文字大小
              },
            },
          },
        ],
        yAxis: [
          {
            splitLine: {
              // 控制刻度横线的显示
              show: false,
            },
            type: "value",
            // max: 20,
            min: 0,
            interval: 10,
            minInterval: 5,
            // name:"推\n送\n班\n级\n数",
            nameLocation: "center",
            nameGap: 35,
            nameRotate: 0,
            nameTextStyle: {
              fontSize: 16,
            },
            axisLabel: {
              formatter: "{value} ",
              show: true,
              textStyle: {
                color: "#666666",
                fontSize: 15,
              },
            },
            axisLine: {
              // Y轴线的颜色、和轴线的宽度
              lineStyle: {
                color: "#D5D5D5",
                width: 2,
              },
            },
          },
          {
            splitLine: {
              show: false,
            },
            type: "value",
            max: 100,
            min: 0,
            interval: 20,
            // name:"平\n均\n完\n成\n率",
            nameLocation: "center",
            nameGap: 50,
            nameRotate: 0,
            nameTextStyle: {
              fontSize: 16,
            },
            axisLine: {
              lineStyle: {
                color: "#D5D5D5",
                width: 2,
              },
            },
          },
        ],
        series: [
          {
            name: "标准值",
            type: "bar",
            yAxisIndex: 0,
            data: this.data2,
            itemStyle: {
              // 双Y轴A柱的柱体颜色
              normal: {
                color: "#77caff",
              },
            },
          },
          {
            name: "测评值",
            type: "bar",
            yAxisIndex: 0,
            data: this.data3,
            itemStyle: {
              // 双Y轴B柱的柱体颜色
              normal: {
                color: "#FFE482",
              },
            },
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },
    initPage() {
      //tree
      switch (this.type) {
        case "subject":
          this.treeData = subjectTreeData;
          break;
        case "device":
          this.treeData = deviceTreeData;
          break;
        default:
          break;
      }
      //clear searchbar

      //loadList
    },
    cxdShowModal(col) {
      // console.log(col.xmbh)
      (this.text = col.cgmc),
        getzzt({
          xmbh: col.xmbh,
        })
          .then((res) => {
            this.data1 = res.data.zbmc;
            this.data2 = res.data.zbbzf;
            this.data3 = res.data.zbpjf;
            console.log(res);
          })
          .then((res) => {
            this.drawChart();
          });
    },
    wcrqkShowModal(mc, col) {
      this.wcrqkVisible = true;
      this.mdl = { ...col };
      getwcr({
        name: mc,
        cgguid: col,
      }).then((res) => {
        this.wcrText = res.data;
      });
    },
    wcdwqkShowModal(wcrmc, col) {
      this.wcdwqkVisible = true;
      this.mdl = { ...col };
      getwcdw({
        name: wcrmc,
        cgguid: col,
      }).then((res) => {
        this.wcdwText = res.data;
      });
    },
    zcxmqkShowModal(zcxmqk, col) {
      this.zcxmqkVisible = true;
      this.mdl = { ...col };
      getzcxmqk({
        name: zcxmqk,
        cgguid: col,
      }).then((res) => {
        this.zcxmqkText = res.data;
      });
    },
    hjqkShowModal(hjqk, col) {
      this.hjqkVisible = true;
      this.mdl = { ...col };
      gethjqk({
        name: hjqk,
        cgguid: col,
      }).then((res) => {
        this.hjqkText = res.data;
      });
    },
    bzqkShowModal(col) {
      this.bzqkVisible = true;
      this.mdl = { ...col };
    },
    zzqkShowModal(zzqk, col) {
      this.zzqkVisible = true;
      this.mdl = { ...col };
      getzz({
        name: zzqk,
        cgguid: col,
      }).then((res) => {
        this.zzqkText = res.data;
      });
    },
    jjxyqkShowModal(col) {
      this.jjxyqkVisible = true;
      this.mdl = { ...col };
      getjjxy({
        cgguid: col.cgguid,
      }).then((res) => {
        this.jjxyText = res.data;
      });
    },
    handleOk(e) {
      this.cxdVisible = false;
      this.wcrqkVisible = false;
      this.wcdwqkVisible = false;
      this.zcxmqkVisible = false;
      this.hjqkVisible = false;
      this.bzqkVisible = false;
      this.zzqkVisible = false;
      this.jjxyqkVisible = false;
    },
    onSearch(value) {
      // console.log(value);
      getdcdfx({
        cgguid: value,
      }).then((res) => {
        this.dataSource = res.data;
      });
    },
    downloadExecl() {
      window.open(`https://xxxxx.com/xxxxxx`, "_blank");
    },
    //上传文件
    uploadFile(info) {
      console.log(info);
      this.fileList = info.fileList;
      // this.uploadData.splice(0)
      //如果没有数据
      if (info.file.response && info.file.response.data == null) {
        this.btnState = true;
      }
      // 有数据并且填写正确
      if (info.file.response && info.file.response.data != null) {
        this.btnState = false;
        // this.fileList.splice(0)
        info.file.response.data.forEach((item, index) => {
          //对列表数据转义赋值
          this.$set(this.uploadData, index, {
            key: index.toString(),
            sort: index + 1,
            id: item.userId,
            phone: item.mobile,
            name: item.name,
            type: item.type,
            count: item.number,
            paytime: `${item.beginData}-${item.endData}`,
          });
        });
        this.list = info.file.response.data;
        console.log(this.uploadData);
      } else if (
        info.file.response &&
        info.file.response.message != null &&
        this.fileList.length > 0
      ) {
        // 如果填写错误
        console.log(info.file.response);
        // this.fileList.splice(0)
        const h = this.$createElement;
        this.$error({
          title: "提醒",
          content: h("div", {}, [
            h("p", `${info.file.response.message}`),
            h("p", "请确认无误后重新上传"),
          ]),
        });
      }
    },
    handleChange(value) {
      // { key: "lucy", label: "Lucy (101)" }
      this.valuechoose = value.key;

      this.uploadUrl =
        this.uploadUrl.substring(0, this.uploadUrl.lastIndexOf("=")) +
        "=" +
        this.valuechoose;
    },
  },
  mounted() {
    this.getTableApi(1);
  },
  computed: {
    type() {
      return this.$route.name.substr(this.$route.name.lastIndexOf("_") + 1);
    },
    // showLeft(){
    //   return this.type!='all'
    // }
  },
  watch: {
    $route(to, from) {
      this.initPage();
    },
  },
  created() {
    this.initPage();
  },
};
</script>

<style>
.main1 {
  width: 95%;
  margin: 0 auto;
  margin-top: 25px;
}
.Echarts {
  width: 95%;
  margin: 0 auto;
  margin-top: 25px;
}
</style>
<style scoped>
.classname {
  color: #1da57a;
  cursor: pointer;
}
.ant-input-search {
  width: 40%;
  margin: 0 8px 8px 0;
}
</style>
